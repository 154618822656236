import { Modal } from "antd";
import React from "react";

import { isOn, linkApp } from "./constants";

export const redirectToDownlad = () => {

    console.log("Redirect", isOn,linkApp)
    if (isOn) {
        window.location.href = linkApp;
    }
    if (!isOn) {
        Modal.info({
            title: 'SuperTalk em desenvolvimento!',
            content: (
                <div>
                    <p>Aplicativo em manutenção no momento. Logo estará disponível para download!</p>
                </div>
            ),
            onOk() { },
        });
    }
}


import React, { useEffect, useState } from "react";
import { useScrollYPosition } from "react-use-scroll-position";
import "../assets/navbar.css";
import { logEventCustom } from "../analyticsUtils";


function Navbar({ links }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const scrollY = useScrollYPosition();

  const stickeyTrigger = window.innerHeight / 2.75;

  useEffect(() => {
    logEventCustom("initial access");
  },[]);

  return (
    <div
      className={`nav${scrollY > stickeyTrigger ? " nav-stickey" : ""}${menuOpen ? " nav-open" : ""
        }`}
      style={{ width: "100%" }}
    >
      <div className="nav-content" >
        <div className="nav-logo">SuperTalk</div>

        <nav className="nav-links__container">
          {links &&
            links.map((link, i) => {
              const currentUrl = new URL(document.location.href);
              const externalControlPages = ["/privacy", "/terms"];

              const referenceLink = externalControlPages.includes(currentUrl.pathname) ? `/${link.href}` : link.href;

              return (<a className="nav-link" href={referenceLink} key={i}>
                <div className="nav-link__text">{link.title}</div>
                <div className="nav-link__background" />
              </a>);
            }
            )}
        </nav>

        <div className="nav-menu__icon" onClick={() => setMenuOpen(!menuOpen)}>
          <div />
          <div />
        </div>
      </div>
    </div>
  );
}

Navbar.defaultProps = {
  links: [
    { title: "Home", href: "#home" },
    { title: "Como funciona?", href: "#features" },
    { title: "Benefícios", href: "#services" },
    { title: "Assinaturas", href: "#pricing" },
    { title: "Contato", href: "#contact" }
  ]
};

export default Navbar;



import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyALxboUo6qUEv6MqlepixTIjRyxN5Ds14M",
  authDomain: "supertalk-landing.firebaseapp.com",
  projectId: "supertalk-landing",
  storageBucket: "supertalk-landing.appspot.com",
  messagingSenderId: "605085463925",
  appId: "1:605085463925:web:201754fbc32f0034380f3c",
  measurementId: "G-9YH4380DL0"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function logEventCustom(event) {
  try {
    const rr = logEvent(analytics, event);
    console.log("Event", event)
  } catch (error) {
    console.error("logEventCustom-Errror",error);
  }
}

export { logEventCustom };
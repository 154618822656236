import React, { Fragment } from "react";
import "./assets/styles.css";
import Navbar from "./components/Navbar";
import { Modal } from 'antd';

import HeroBanner from "./components/HeroBanner";
import Features from "./components/Features";
import Services from "./components/Services";
import Screenshots from "./components/Screenshots";
import Pricing from "./components/Pricing";
import Footer from "./components/Footer";
import Download from "./components/Download";
import styled from 'styled-components';

export default function App() {

  const MainContent = styled.main`
    section {
      height: auto !important;
    }
    .pricing-upper {
      margin-bottom: 15px;
    }
    .nav { 
    width: 100% !important;
  }

  .nav-stickey {
    width: 100% !important;
  }
  `;

// Logged event initalization
  

  const initLogger =  () => {
    console.log("init")
  }

  return (
    <Fragment onload={() =>initLogger() }>
      <Navbar />
      <MainContent>
        <HeroBanner key={"HeroBanner"} />
        <Features key={"Features"} />
        {/* //TODO verificar necessidade */}
        <Services key={"Services"} />
        <Screenshots key={"Screenshots"} />
        <Pricing key={"Pricing"} />
        <Download key={"Download"} />
      </MainContent>
      <Footer />
    </Fragment>
  );
}

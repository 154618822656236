import React, { Fragment } from 'react';
import Navbar from '../Navbar';
import styled from 'styled-components';
import Footer from '../Footer';

export default function PolicyContainer({ children }) {
    const DividerCMP = styled.div`
    height:100px;
    `;

    const MainContent = styled.main`
  section {
    height: auto !important;
  }
  .pricing-upper {
    margin-bottom: 15px;
  }
  .nav { 
  width: 100% !important;
  /* position: initial !important; */
}

.nav-stickey {
  width: 100% !important;
}
.content {
    padding: 5px;
}
`;

    const hiddenHeader = new URLSearchParams(document.location.search).get("hiddenHeader") === "true";
    return (hiddenHeader ? <Fragment> {{ ...children }}</Fragment> : <Fragment>
        <Navbar />
        <DividerCMP />
        <MainContent>
            {{ ...children }}
        </MainContent>
        <Footer />
    </Fragment>);
}
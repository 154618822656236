import React from "react";
import { redirectToDownlad } from "../utils";

function HeroBanner() {
  return (
    <section id="home">
      <div className="container">
        <div className="home-text">
          <div className="section-text__subtitle">App SuperTalk</div>
          <div className="section-text__title-big">App para ligações.</div>
          <div className="section-text__body">
            {/* //TODO definir esta mensagem com mais efeito */}
            SuperTalk é um aplicativo que permite fazer chamadas de voz pela internet sem necessidade de possuir um número. 
            Isso é providenciado pelo app, tornando mais fácil e essencial para pessoas que desejam fazer ligações anônimas sem expor seus números pessoais.
          </div>
          <a href="#download" className="download-btn" >
            Baixar App
          </a>
        </div>

        <div className="section-image">
          
        <img src="./images/phone_app.png" alt="app preview" />
          {/* <img src="./images/hero_right.webp" alt="app preview" /> */}
        </div>
      </div>
    </section>
  );
}

export default HeroBanner;
